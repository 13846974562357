import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Badges from "../components/Repeating/Badges";
import CallToAction from "../components/Repeating/CTA";
import estatePlanning from "../images/2.0 Practice Areas/01 Estate Planning.jpg";
import probate from "../images/2.0 Practice Areas/02 Probate.jpg";
import trustAdmin from "../images/2.0 Practice Areas/03 Trust Administration.jpg";
import conservatorship from "../images/2.0 Practice Areas/04 Conservatorship.jpg";
import guardianship from "../images/2.0 Practice Areas/05 Guardianship.jpg";
import specialNeedsTrust from "../images/2.0 Practice Areas/06 Special Needs Trust.jpg";
import probateLitigation from "../images/2.0 Practice Areas/07 Probate Litigation.jpg";
import trustLitigation from "../images/2.0 Practice Areas/08 Trust Litigation.jpg";
import trustEstate from "../images/2.0 Practice Areas/09 Trust _ Estate Mediation.jpg";
import realPropertyTax from "../images/2.0 Practice Areas/10.0 Real Property Tax Consultation.jpg";
import estateTax from "../images/2.0 Practice Areas/11 Estate Tax Returns.jpg";
import accountings from "../images/2.0 Practice Areas/12 Accountings.jpg";

const practiceAreas = [
  {
    url: "/estate-planning-attorney-san-diego/",
    image: estatePlanning,
    label: "Estate Planning",
  },
  {
    url: "/probate-attorney-san-diego/",
    image: probate,
    label: "Probate",
  },
  {
    url: "/trust-administration-lawyer/",
    image: trustAdmin,
    label: "Trust Administration",
  },
  {
    url: "/conservatorship-lawyer/",
    image: conservatorship,
    label: "Conservatorship",
  },
  {
    url: "/guardianship-lawyer/",
    image: guardianship,
    label: "Guardianship",
  },
  {
    url: "/special-needs-trust-lawyer/",
    image: specialNeedsTrust,
    label: "Special Needs Trust",
  },
  {
    url: "/probate-litigation-lawyer/",
    image: probateLitigation,
    label: "Probate Litigation",
  },
  {
    url: "/trust-estate-litigation-lawyer/",
    image: trustLitigation,
    label: "Trust Litigation",
  },
  {
    url: "/trust-estate-mediation/",
    image: trustEstate,
    label: "Trust & Estate Mediation",
  },
  {
    url: "/parent-grandparent-child-exclusions/",
    image: realPropertyTax,
    label: "Property Transfers",
  },
  {
    url: "/estate-tax-returns/",
    image: estateTax,
    label: "Estate Tax Returns",
  },
  {
    url: "/trust-accountings/",
    image: accountings,
    label: "Accountings",
  },
];

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Practice Areas | Brierton, Jones & Jones"
        description="View a list of our practice areas at Brierton, Jones & Jones here. We specialize in trust, estate and probate litigation among other areas. Call us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-gray-50 pb-8 pt-18 md:pb-16 md:pt-26">
        <div className="container">
          <header className="max-w-[756px]">
            <h1>Our Practice Areas</h1>
            <p className="mb-0">
              Brierton, Jones & Jones attorneys hold expertise in a wide range
              of practice areas. We can help you with the journey you’re on and
              the goals you have in mind.
            </p>
          </header>
        </div>
      </section>

      <section className="pb-26 pt-10 md:pt-16">
        <div className="container">
          <div className="grid grid-cols-2 gap-x-6 gap-y-5 md:grid-cols-4 md:gap-y-10">
            {practiceAreas
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((practiceArea, index) => (
                <PracticeAreaCard practiceArea={practiceArea} key={index} />
              ))}
            {/* <div className="group inline-block">
              <Link
                to="/estate-planning-attorney-san-diego/"
                className="no-underline"
              >
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/01 Estate Planning.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Estate Planning
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/probate-attorney-san-diego/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/02 Probate.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Probate
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/trust-administration-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/03 Trust Administration.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Trust Administration
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/conservatorship-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/04 Conservatorship.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Conservatorship
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/guardianship-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/05 Guardianship.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Guardianship
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/special-needs-trust-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/06 Special Needs Trust.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Special Needs Trust
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/probate-litigation-lawyer/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/07 Probate Litigation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Probate Litigation
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link
                to="/trust-estate-litigation-lawyer/"
                className="no-underline"
              >
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/08 Trust Litigation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Trust Litigation
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/trust-estate-mediation/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/09 Trust _ Estate Mediation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Trust & Estate Mediation
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link
                to="/parent-grandparent-child-exclusions/"
                className="no-underline"
              >
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/10.0 Real Property Tax Consultation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Property Transfers
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/estate-tax-returns/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/11 Estate Tax Returns.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Estate Tax Returns
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/trust-accountings/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/12 Accountings.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Accountings
                </h3>
              </Link>
            </div>

            <div className="group inline-block">
              <Link to="/agent-representation/" className="no-underline">
                <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
                  <StaticImage
                    src="../images/2.0 Practice Areas/13 Agent Representation.jpg"
                    loading="lazy"
                    width={282}
                    className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                  />
                </div>
                <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
                  Agent Representation
                </h3>
              </Link>
            </div>
            <PracticeAreaCard
              image={"../images/2.0 Practice Areas/01 Estate Planning.jpg"}
              label="Estate Planning"
              url="/estate-planning-attorney-san-diego/"
            /> */}
          </div>
        </div>
      </section>

      <section className="bg-blue py-18 md:py-24">
        <div className="container">
          <Badges />
        </div>
      </section>
      <CallToAction />
    </Layout>
  );
};

const PracticeAreaCard = ({ practiceArea }) => {
  return (
    <div className="group inline-block">
      <Link to={practiceArea.url} className="no-underline">
        <div className="mb-4 overflow-hidden rounded-br-[90px] rounded-tr-[90px]">
          <img
            src={practiceArea.image}
            loading="lazy"
            width={282}
            className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
          />
        </div>
        <h3 className="mb-0 font-display text-lg font-semibold md:text-xl">
          {practiceArea.label}
        </h3>
      </Link>
    </div>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
